export type Values<T> = T[keyof T]

export function isNullish<T>(
  argument: T | undefined | null
): argument is undefined | null {
  return argument === null || argument === undefined
}

export function hasLength(value: any[] | undefined | null): boolean {
  if (isNullish(value)) return false
  return Number(value?.length) != 0
}

export function nonZero(value: number | undefined | null): boolean {
  if (isNullish(value)) return false
  return Number(value) > 0
}
